import gql from "graphql-tag";
import {
  Script,
  ScriptParameterDetails,
  ScriptRevision,
  PublicSyndication,
  PublicUser,
  Execution,
} from "../schema";

export const ASSET_DATA = gql`
  fragment AssetData on Asset {
    currency
    amount
  }
`;

export const ASSETDETAILS_DATA = gql`
  fragment AssetDetailsData on AssetDetails {
    free {
      ...AssetData
    }
    updatedAt
  }
  ${ASSET_DATA}
`;

export const USER_DATA = gql`
  fragment UserData on User {
    id
    firstName
    lastName
    email
    termsAccepted
    welcomed
    tier
    tierParameters {
      concurrentBatchTests
      paperTradesCount
      batchTestMaxSizeAccuracy
      batchTestMaxCandlesAmount
    }
    permissions
    registrationDate
    companyName
    nickname
    country
    description
    twitterUrl
    discordUrl
    telegramUrl
    mediumUrl
    linkedinUrl
    personalBlogUrl
    personalWebsiteUrl
    profilePhotoS3Key
    profilePhotoUrl
    outstandingInvoiceCount
    availablePaymentProviders
    mailingPreference {
      status
      userType
    }
  }
`;

export const CREDENTIAL_DATA = gql`
  fragment CredentialData on Credential {
    id
    name
    exchange
    maskedApiKey
    status
  }
`;

export type CredentialDataFields =
  | "id"
  | "name"
  | "exchange"
  | "maskedApiKey"
  | "status";

export const SCRIPTPARAMETER_FULL_DATA = gql`
  fragment ScriptParameterFullData on ScriptParameterDetails {
    fieldName
    valueType
    readableName
    description
    defaultValue
    options
  }
`;

export const PLOTINFO_DATA = gql`
  fragment PlotInfoData on PlotInfo {
    id
    title
    color {
      type
      value
    }
    lineWidth
    lineStyle
    transparency
    trackPrice
    histBase
    offset
    editable
  }
`;

export const PLOTARROWINFO_DATA = gql`
  fragment PlotArrowInfoData on PlotArrowInfo {
    id
    title
    colorUp {
      type
      value
    }
    colorDown {
      type
      value
    }
    transparency
    offset
    minHeight
    maxHeight
    editable
  }
`;

export const PLOTCHARINFO_DATA = gql`
  fragment PlotCharInfoData on PlotCharInfo {
    id
    title
    char
    location
    color {
      type
      value
    }
    transparency
    offset
    text
    textColor {
      type
      value
    }
    size
    editable
  }
`;

export const PLOTSHAPEINFO_DATA = gql`
  fragment PlotShapeInfoData on PlotShapeInfo {
    id
    title
    style
    location
    color {
      type
      value
    }
    transparency
    offset
    text
    textColor {
      type
      value
    }
    size
    editable
  }
`;

export const FILLINFO_DATA = gql`
  fragment FillInfoData on FillInfo {
    id
    inputType
    input1 {
      str
    }
    input2 {
      str
    }
    color {
      type
      value
    }
    transparency
    title
    editable
  }
`;

export const HLINEINFO_DATA = gql`
  fragment HLineInfoData on HLineInfo {
    id
    title
    yVal
    color {
      type
      value
    }
    lineWidth
    lineStyle
    editable
  }
`;

export const BGCOLORINFO_DATA = gql`
  fragment BGColorInfoData on BGColorInfo {
    color {
      type
      value
    }
    transparency
    offset
    title
    editable
  }
`;

export const BARCOLORINFO_DATA = gql`
  fragment BarColorInfoData on BarColorInfo {
    color {
      type
      value
    }
    offset
    title
    editable
  }
`;

export const PLOT_GROUP_FULLDATA = gql`
  fragment PlotGroupFullData on ScriptPlotGroup {
    id
    isOverlay
    plots {
      ...PlotInfoData
    }
    arrows {
      ...PlotArrowInfoData
    }
    chars {
      ...PlotCharInfoData
    }
    shapes {
      ...PlotShapeInfoData
    }
    fills {
      ...FillInfoData
    }
    hLines {
      ...HLineInfoData
    }
    bgColor {
      ...BGColorInfoData
    }
    barColor {
      ...BarColorInfoData
    }
  }
  ${PLOTINFO_DATA}
  ${PLOTARROWINFO_DATA}
  ${PLOTCHARINFO_DATA}
  ${PLOTSHAPEINFO_DATA}
  ${FILLINFO_DATA}
  ${HLINEINFO_DATA}
  ${BGCOLORINFO_DATA}
  ${BARCOLORINFO_DATA}
`;

export const PLOT_DEFINITION_DATA = gql`
  fragment PlotDefinitionData on ScriptPlotDefinition {
    groups {
      ...PlotGroupFullData
    }
  }
  ${PLOT_GROUP_FULLDATA}
`;

export const SCRIPT_ERROR_DATA = gql`
  fragment ScriptErrorData on ScriptError {
    message
    range {
      start {
        line
        char
      }
      end {
        line
        char
      }
    }
  }
`;

export const SCRIPT_REVISION_DATA = gql`
  fragment ScriptRevisionData on ScriptRevision {
    id
    status
    parameters {
      ...ScriptParameterFullData
    }
    plotDefinition {
      ...PlotDefinitionData
    }
    errors {
      ...ScriptErrorData
    }
    warnings {
      ...ScriptErrorData
    }
    infos {
      ...ScriptErrorData
    }
  }
  ${SCRIPTPARAMETER_FULL_DATA}
  ${PLOT_DEFINITION_DATA}
  ${SCRIPT_ERROR_DATA}
`;

export type ScriptRevisionDataFields =
  | "id"
  | "status"
  | "parameters"
  | "errors"
  | "warnings"
  | "infos";

export const SCRIPTREVISION_FULL_DATA = gql`
  fragment ScriptRevisionFullData on ScriptRevision {
    id
    body
    status
    parameters {
      ...ScriptParameterFullData
    }
    plotDefinition {
      ...PlotDefinitionData
    }
    errors {
      ...ScriptErrorData
    }
    warnings {
      ...ScriptErrorData
    }
    infos {
      ...ScriptErrorData
    }
    createdAt
    updatedAt
  }
  ${SCRIPTPARAMETER_FULL_DATA}
  ${PLOT_DEFINITION_DATA}
  ${SCRIPT_ERROR_DATA}
`;

export const SCRIPTREVISION_DATE_DATA = gql`
  fragment ScriptRevisionDateData on ScriptRevision {
    id
    createdAt
    updatedAt
  }
`;

export type ScriptRevisionFullData = Pick<
  ScriptRevision,
  | "id"
  | "body"
  | "status"
  | "parameters"
  | "plotDefinition"
  | "errors"
  | "warnings"
  | "infos"
  | "createdAt"
  | "updatedAt"
>;

export const SCRIPT_MAIN_DATA = gql`
  fragment ScriptMainData on Script {
    id
    name
    language
  }
`;

export const SCRIPT_LIST_DATA = gql`
  fragment ScriptListData on Script {
    id
    isOwner
    name
    language
    favorite
    notes
    isPublic
    tags {
      id
      name
      color
    }
    latestVersionNumber
    ownerInfo {
      author
      nickname
    }
    timesCopied
    isUsed
  }
`;

export type ScriptMainData = Pick<
  Script,
  "id" | "name" | "language" | "isUsed"
>;

export type ScriptListData = Pick<
  Script,
  | "id"
  | "name"
  | "favorite"
  | "notes"
  | "isPublic"
  | "tags"
  | "latestVersionNumber"
  | "latest"
  | "ownerInfo"
  | "timesCopied"
>;

export type ScriptWithParameters = ScriptMainData & {
  latestValid: {
    id: string;
    parameters: ScriptParameterDetails[];
  };
};

export const SCRIPTDETAILS_MAIN_DATA = gql`
  fragment ScriptDetailsMainData on ScriptDetails {
    id
    scriptId
    revisionId
    name
    language
    version
    isLatest
    createdAt
  }
`;

export const SCRIPTDETAILS_FULL_DATA = gql`
  fragment ScriptDetailsFullData on ScriptDetails {
    id
    scriptId
    revisionId
    name
    language
    body
    parameters {
      fieldName
      readableName
      valueType
      defaultValue
    }
    plotDefinition {
      ...PlotDefinitionData
    }
  }
  ${PLOT_DEFINITION_DATA}
`;

export const MEASUREMENT_DATA = gql`
  fragment MeasurementData on Measurements {
    absoluteProfit
    avgBarsInTrade
    numberOfTrades
    maxDrawdown
    percentProfitableTrades
    profitability
    profitFactor
    buyHoldRatio
    buyHoldReturn
    avgTradePrice
    avgPositionPrice
    lastTick
    positionProfitLoss
    avgMonthlyProfit
    avgWinMonth
    avgLoseMonth
    percProfitableMonths
    positionAbsoluteProfit
    positionAmount
    positionProfitLoss
    balance
    riskScore
    buyHoldReturn
    sharpeRatio
    sortinoRatio
    totalRealizedGain
    totalRealizedLoss
    consistencyScore
  }
`;

export const TRADES_MEASUREMENT_DATA = gql`
  fragment TradesMeasurementData on Measurements {
    numberOfTrades
    positionProfitLoss
    positionAbsoluteProfit
  }
`;

export const ORDER_MAIN_DATA = gql`
  fragment OrderMainData on Order {
    externalId
    source
    status
    side
    placedTime
    placedAmount
    filledTime
    commissionPaid
    limitPrice
  }
`;

export type OrderMainDataFields =
  | "externalId"
  | "source"
  | "status"
  | "side"
  | "placedTime"
  | "placedAmount"
  | "filledTime"
  | "commissionPaid";

export const ORDER_PRE_COMMISSION_DATA = gql`
  fragment OrderPreCommissionData on Order {
    filledAmount
    filledPrice
    filledValue
  }
`;

export type OrderPreCommissionDataFields =
  | "filledAmount"
  | "filledPrice"
  | "filledValue";

export const ORDER_POST_COMMISSION_DATA = gql`
  fragment OrderPostCommissionData on Order {
    effectiveAmount
    effectivePrice
    effectiveValue
  }
`;

export const TRADE_DATA = gql`
  fragment TradeData on Trade {
    id
    orders {
      ...OrderMainData
      ...OrderPreCommissionData
      ...OrderPostCommissionData
    }
    absoluteProfit
    profitPercentage
    isClosed
    isProfitable
    fundingFee
    currencyPairDetails {
      id
      exchange
      pair
      base
      quote
      settleCurrency
      positionCurrency
    }
  }
  ${ORDER_MAIN_DATA}
  ${ORDER_PRE_COMMISSION_DATA}
  ${ORDER_POST_COMMISSION_DATA}
`;

export const EXECUTION_LOG_DATA = gql`
  fragment ExecutionLogData on ExecutionLog {
    id
    type
    time
  }
`;

export type OrderPostCommissionDataFields =
  | "effectiveAmount"
  | "effectivePrice"
  | "effectiveValue";

export const EXECUTION_CONTROL_DATA = gql`
  fragment ExecutionControlData on Execution {
    id
    name
    type
    status
    currencyPair
    currencyPairDetails {
      id
      exchange
      pair
      base
      quote
      settleCurrency
      positionCurrency
    }
    multiCoinCurrency
    exchange
    candleSize
    candleType
    rangeStart
    rangeEnd
    startedAt
    endedAt
    plotDefinition {
      ...PlotDefinitionData
    }
    plotPalettes {
      plotId
      colors
    }
    runNumber
  }
  ${PLOT_DEFINITION_DATA}
`;

export type ExecutionControlDataFields =
  | "id"
  | "name"
  | "type"
  | "status"
  | "currencyPair"
  | "exchange"
  | "candleSize"
  | "candleType"
  | "rangeStart"
  | "rangeEnd"
  | "startedAt"
  | "endedAt"
  | "plotDefinition"
  | "plotPalettes";

export const MULTIVARIANT_LIST_DATA = gql`
  fragment MultivariantListData on Multivariant {
    id
    name
    exchange
    currencyPair
    currencyPairDetails {
      id
      exchange
      pair
      base
      quote
      settleCurrency
      positionCurrency
    }
    candleSize
    candleType
    maxBuy
    minProfit
    stopLossPercentage
    stopLossTrailing
    takeProfitPercentage
    takeProfitTrailingPercentage
    algorithmSettings
    scriptDetails {
      ...ScriptDetailsMainData
      parameters {
        readableName
        fieldName
      }
    }
    status
    progress {
      status
      count
    }
    totalCombinationsCount
    canResume
    createdAt
    favorite
    scriptVersionSequence
    tags {
      id
      name
      color
    }
    allocation
    performanceMode
    leverage
    leverageShort
    lookbackDays
    buyActionTime
    sellActionTime
  }
  ${SCRIPTDETAILS_MAIN_DATA}
`;

export type MultivariantListDataFields =
  | "id"
  | "name"
  | "exchange"
  | "currencyPair"
  | "currencyPairDetails"
  | "candleSize"
  | "candleType"
  | "algorithmSettings"
  | "canResume"
  | "scriptDetails"
  | "status"
  | "totalCombinationsCount"
  | "maxBuy"
  | "minProfit"
  | "stopLossPercentage"
  | "stopLossTrailing"
  | "takeProfitPercentage"
  | "takeProfitTrailingPercentage"
  | "progress"
  | "createdAt"
  | "scriptVersionSequence"
  | "tags"
  | "allocation"
  | "leverage"
  | "leverageShort"
  | "lookbackDays"
  | "buyActionTime"
  | "sellActionTime";

export const MULTIVARIANT_DETAILS_DATA = gql`
  fragment MultivariantDetailsData on Multivariant {
    id
    lookbackDays
    rangeStart
    rangeEnd
    buyActionTime
    sellActionTime
    allocation
    leverage
    leverageShort
    createdAt
  }
`;

export const MULTIVARIANT_COMPOSER_DATA = gql`
  fragment MultivariantComposerData on Multivariant {
    id
    multipleValueFields
  }
`;

export const SHARED_EXECUTION_DATA = gql`
  fragment SharedExecutionData on SharedExecution {
    shareToken
    name
    syndicationName
    exchange
    currencyPair
    currencyPairDetails {
      id
      exchange
      pair
      base
      quote
      settleCurrency
      positionCurrency
    }
    multiCoinCurrency
    candleSize
    measurements {
      ...MeasurementData
    }
  }
  ${MEASUREMENT_DATA}
`;

export const EXECUTION_SUBSCRIPTION_DATA = gql`
  fragment ExecutionSubscriptionData on Execution {
    id
    status
    measurements {
      ...MeasurementData
    }
  }
  ${MEASUREMENT_DATA}
`;

export const EXECUTION_LIST_DATA = gql`
  fragment ExecutionListData on Execution {
    id
    name
    type
    exchange
    currencyPair
    currencyPairDetails {
      id
      exchange
      pair
      base
      quote
      settleCurrency
      positionCurrency
    }
    candleSize
    candleType
    measurements {
      ...MeasurementData
    }
    scriptDetails {
      ...ScriptDetailsMainData
    }
    status
    favorite
    error
    runtimeSeconds
    shareToken
    algorithmSettings
    createdAt
    leverage
    leverageShort
    multivariantIterationId
    scriptVersionSequence
    runNumber
    tags {
      id
      name
      color
    }
    allocation
    rangeStart
    rangeEnd
    isPack
    stage
    syndicationId
    syndicationSettings {
      accessControl
    }
  }
  ${MEASUREMENT_DATA}
  ${SCRIPTDETAILS_MAIN_DATA}
`;

export const EXECUTION_PREVIEW_DATA = gql`
  fragment ExecutionPreviewData on Execution {
    id
    type
    exchange
    currencyPair
    candleSize
    candleType
    measurements {
      ...MeasurementData
    }
    scriptDetails {
      ...ScriptDetailsMainData
    }
    status
    error
    algorithmSettings
    createdAt
    leverage
    leverageShort
    scriptVersionSequence
    runNumber
    allocation
    rangeStart
    rangeEnd
    stage
  }
  ${MEASUREMENT_DATA}
  ${SCRIPTDETAILS_MAIN_DATA}
`;

export const MULTIVARIANT_EXECUTIONS_DATA = gql`
  fragment MultivariantExecutionsData on Multivariant {
    id
    executions(query: $query, sort: $sort, filters: $filters) {
      ...ExecutionListData
    }
    executionsCount(filters: $filters) {
      count
    }
  }
  ${EXECUTION_LIST_DATA}
`;

export type ExecutionListDataFields =
  | "id"
  | "name"
  | "type"
  | "exchange"
  | "currencyPair"
  | "candleSize"
  | "candleType"
  | "measurements"
  | "scriptDetails"
  | "status"
  | "favorite"
  | "isPack"
  | "syndicationSettings";

export type PackExecutionDataFields = Pick<
  Execution,
  "id" | "type" | "exchange" | "currencyPair" | "packPercentage"
>;

export const EXECUTION_DETAILS_DATA = gql`
  fragment ExecutionDetailsData on Execution {
    id
    name
    currencyPair
    currencyPairDetails {
      id
      exchange
      pair
      base
      quote
      settleCurrency
      positionCurrency
    }
    multiCoinCurrency
    multiCoinPackExecutions {
      currency
      percentage
    }
    candleSize
    candleType
    allocation
    type
    exchange
    status
    measurements {
      ...MeasurementData
    }
    scriptDetails {
      ...ScriptDetailsFullData
    }
    rangeStart
    rangeEnd
    maxBuy
    minProfit
    stopLossPercentage
    stopLossTrailing
    takeProfitPercentage
    takeProfitTrailingPercentage
    buyActionTime
    sellActionTime
    buyType
    sellType
    algorithmSettings
    lookbackDays
    leverage
    leverageShort
    scriptVersionSequence
    runNumber
    multivariantIterationId
    tags {
      id
      name
      color
    }
    error
    syndicationId
    syndication {
      id
      name
    }
    syndicationSettings {
      description
      maxAllocation
      minAllocation
      monthlyFeeUsd
      allocationFeePerc
      successFeePerc
      maxSubscriberAllocation
      availableAllocation
      accessControl
    }
    performanceMode
    owner {
      companyName
      nickname
      firstName
      lastName
    }
  }
  ${MEASUREMENT_DATA}
  ${SCRIPTDETAILS_FULL_DATA}
`;

export type ExecutionDetailsDataFields =
  | "id"
  | "name"
  | "currencyPair"
  | "currencyPairDetails"
  | "candleSize"
  | "candleType"
  | "allocation"
  | "type"
  | "exchange"
  | "status"
  | "measurements"
  | "scriptDetails"
  | "rangeStart"
  | "rangeEnd"
  | "maxBuy"
  | "minProfit"
  | "stopLossPercentage"
  | "stopLossTrailing"
  | "takeProfitPercentage"
  | "takeProfitTrailingPercentage"
  | "buyActionTime"
  | "sellActionTime"
  | "buyType"
  | "sellType"
  | "algorithmSettings"
  | "lookbackDays"
  | "status"
  | "error";

export const ORDERRULES_DATA = gql`
  fragment OrderRulesData on OrderRules {
    minOrderAmount
    maxOrderAmount
    minOrderValue
  }
`;

export type OrderRulesDataFields =
  | "minOrderAmount"
  | "maxOrderAmount"
  | "minOrderValue";

export const CURRENCYPAIRDETAILS_DATA = gql`
  fragment CurrencyPairDetailsData on CurrencyPairDetails {
    id
    exchange
    pair
    base
    quote
    settleCurrency
    positionCurrency
  }
`;

export type CurrencyPairDetailsDataFields =
  | "id"
  | "exchange"
  | "pair"
  | "base"
  | "quote";

export const PUBLIC_SYNDICATION_DATA = gql`
  fragment PublicSyndicationData on PublicSyndication {
    id
    name
    exchange
    currencyPair
    multiCoinCurrency
    multiCoinPackExecutions {
      currency
      percentage
    }
    syndicationSettings {
      id
      maxAllocation
      minAllocation
      description
      monthlyFeeUsd
      allocationFeePerc
      successFeePerc
      maxSubscriberAllocation
      availableAllocation
      accessControl
    }
    measurements {
      subscriptionAllocation
    }
    owner {
      companyName
      nickname
      firstName
      lastName
    }
    leverageInfo {
      maxLeverage
    }
  }
`;

export const PUBLIC_SYNDICATION_LIST_DATA = gql`
  fragment PublicSyndicationListData on PublicSyndication {
    id
    name
    exchange
    currencyPair
    currencyPairDetails {
      id
      exchange
      pair
      base
      quote
      settleCurrency
      positionCurrency
    }
    multiCoinCurrency
    multiCoinPackExecutions {
      currency
      percentage
    }
    syndicationSettings {
      id
      maxAllocation
      minAllocation
      description
      monthlyFeeUsd
      allocationFeePerc
      successFeePerc
      accessControl
    }
    measurements {
      maxDrawdown
      avgMonthlyProfit
      percProfitableMonths
      riskScore
      subscriptionCount
      subscriptionAllocation
      profitability
    }
    startedAt
  }
`;

export const ANALYSIS = gql`
  fragment AnalysisData on Analysis {
    bestMonth
    worstMonth
    avgEquityPeakDays
    dailyProfits {
      firstDay
      values
    }
    monthlyProfits {
      firstMonth
      values
    }
    realizedProfitPerc
  }
`;

export const PUBLIC_SYNDICATION_DETAILS_DATA = gql`
  fragment PublicSyndicationDetailsData on PublicSyndication {
    id
    name
    exchange
    currencyPair
    currencyPairDetails {
      id
      exchange
      pair
      base
      quote
      settleCurrency
      positionCurrency
    }
    multiCoinCurrency
    multiCoinPackExecutions {
      currency
      percentage
    }
    syndicationSettings {
      id
      maxAllocation
      maxSubscriberAllocation
      availableAllocation
      minAllocation
      description
      monthlyFeeUsd
      allocationFeePerc
      successFeePerc
      productId
      accessControl
    }
    measurements {
      maxDrawdown
      avgMonthlyProfit
      percProfitableMonths
      riskScore
      subscriptionCount
      subscriptionAllocation
    }
    backtestMeasurements {
      maxDrawdown
      avgMonthlyProfit
      percProfitableMonths
      riskScore
    }
    startedAt
    backtestAnalysis {
      ...AnalysisData
    }
    leverageInfo {
      maxLeverage
      minLeverage
      avgLeverage
    }
    performance
    subscription {
      id
      statusDetails {
        creationFailure {
          reason
        }
        latestRenewalFailure {
          reason
        }

        mayUnsubscribe
        mayRevokeUnsubscription
        mayUseEntitlements
        hasPaymentPending

        hasCreationFailed
        hasLatestRenewalFailed
        willBeCancelledAtEndOfCycle
        userHasUnsubscribed
      }
    }
    owner {
      companyName
      nickname
    }
  }
  ${ANALYSIS}
`;

export type PublicSyndicationListData = Pick<
  PublicSyndication,
  | "id"
  | "name"
  | "exchange"
  | "currencyPair"
  | "currencyPairDetails"
  | "multiCoinCurrency"
  | "multiCoinPackExecutions"
  | "syndicationSettings"
  | "measurements"
  | "startedAt"
  | "subscription"
  | "analysis"
  | "owner"
>;

export type PublicSyndicationMetricsData = Pick<
  PublicSyndication,
  "id" | "performance" | "analysisWithRange"
>;

export type PublicSyndicationSearchData = Pick<
  PublicSyndication,
  "id" | "name" | "owner"
>;

export type PublicUserSearchData = Pick<PublicUser, "id" | "nickname">;

export const SYNDICATION_UPDATE = gql`
  fragment SyndicationUpdate on SyndicationUpdate {
    id
    syndicationId
    createdAt
    updatedAt
    placement
    audience
    update
  }
`;

export const PROMOTION_DATA = gql`
  fragment PromotionData on Promotion {
    id
    status
    refId
    promoCode
    promoterId
    campaignId
    referralLink
  }
`;

export const REFERRAL_LEAD_DATA = gql`
  fragment ReferralLeadData on ReferralLead {
    id
    state
    email
    customerSince
    planName
    suspicion
    registrationDate
  }
`;

export const PROMOTER_DATA = gql`
  fragment PromoterData on Promoter {
    id
    custId
    email
    defaultRefId
    promotions {
      ...PromotionData
    }
    leads {
      ...ReferralLeadData
    }
  }
  ${REFERRAL_LEAD_DATA}
  ${PROMOTION_DATA}
`;

export const USER_PROMOTER_DATA = gql`
  fragment UserPromoterData on User {
    id
    promoterDetails {
      ...PromoterData
    }
  }
  ${PROMOTER_DATA}
`;

export const TARIFF_PLAN_DATA = gql`
  fragment TariffPlanData on TariffPlan {
    id
    name
    isMonthly
    liveMarketData
    tunedScript
    pineScript
    detailedDocumentation
    strategyBrowser
    stopLoss
    takeProfit
    minimumProfit
    batchTestCount
    concurrentBatchTestsCount
    paperTradesCount
    downloadTrades
    pricingAmount
    description
    mostPopular
  }
`;
